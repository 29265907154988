module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"UA-3840444-31","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"688885231526367"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
